import React, { useCallback, useEffect } from 'react';
import useList from 'react-use/lib/useList';
import { isEqual } from 'lodash';
import Checkbox from '@components/checkbox';
import MyButton from '@components/Button';
import styles from '@styles/components/MultiCheck.module.scss';
type Props = {
  context?: {
    selections?: string,
    setValue?: Function,
    value?: Array,
    configs?: Array,
    titleMobile?: string,
  },
  close?: Function,
};

export default React.memo(MultiCheckDropdownComponent, isEqual);

function MultiCheckDropdownComponent(props: Props) {
  const { context, close } = props,
    {
      configs: selections = [],
      value: initialValue,
      setValue,
      titleMobile,
    } = context;

  const [value, { filter, push, set }] = useList([]);

  useEffect(() => {
    if (initialValue?.length > 0) {
      set([...initialValue]);
    }
  }, [initialValue]);

  const isCheckedAll = value?.length === selections?.length,
    isPartialCheckedAll =
      value?.length > 0 && value?.length < selections?.length;
  const onCheckedAll = useCallback(() => {
    if (isCheckedAll) {
      set([]);
    } else {
      set([...selections?.map((item) => item.key)]);
    }
  }, [isCheckedAll, selections, set]);

  const onCheckedItem = useCallback(
    (key, isChecked) => {
      if (isChecked) {
        filter((e) => e !== key);
      } else {
        push(key);
      }
    },
    [filter, push],
  );

  const onConfirm = useCallback(() => {
    setValue([...value]);
    close();
  }, [close, setValue, value]);

  return (
    <div
      style={{
        minWidth: 250,
        // maxHeight: 300,
        boxShadow: '-1px 0 5px 0 rgba(0, 0, 0, 0.12)',
      }}
      className="w-full flex-1 bg-white rounded">
      <div
        className="w-full flex-col flex sm:hidden border-b pr-3 py-1 bg-white sticky top-0 z-10 h-50px"
        style={{
          borderColor: '#e2e2e2',
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}>
        <div className="flex justify-center items-center">
          <div className="w-32px h-4px rounded-full bg-gray-500" />
        </div>
        <div className="flex flex-1 items-center justify-center">
          <button onClick={close} className="w-48px ">
            <img alt="down" src="/svg/rectangleDownIcon.svg" />
          </button>
          <div className="flex flex-1 justify-center items-center px-2 text-base font-bold">
            {titleMobile}
          </div>
          <div onClick={onConfirm} className="text-sm text-blue-main font-bold">
            Xác nhận
          </div>
        </div>
      </div>
      <div className="flex-1 px-4 py-6 space-y-4 h-250px overflow-scroll">
        <Checkbox
          title="Tất cả"
          isChecked={isCheckedAll}
          isPartialChecked={isPartialCheckedAll}
          onPress={onCheckedAll}
        />
        {selections.map((item, index) => {
          const isChecked = value.includes(item.key);
          return (
            <Checkbox
              key={index}
              title={item.value}
              wrapperStyle={{ marginBottom: 16 }}
              isChecked={isChecked}
              onPress={() => onCheckedItem(item.key, isChecked)}
            />
          );
        })}
      </div>
      <div
        style={{
          width: '99%',
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        className={`w-full px-3 py-2 flex flex-row border-t border-gray-300 sticky bottom-0 space-x-2 ${styles.bottom}`}>
        <MyButton
          wrapperClass="flex-1"
          onPress={close}
          label="Trở về"
          type="secondary"
        />
        <MyButton
          disabled={!value?.length}
          wrapperClass="flex-1"
          onPress={onConfirm}
          label="Xác nhận"
          type="primary"
        />
      </div>
    </div>
  );
}
