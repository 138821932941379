import React, { useEffect, useMemo, useState } from 'react';
import MyButton from '@components/Button';
import Radio from '@components/Radio';
import ReactSliderComponentV2 from '@components/reactSliderComponentV2';
import Dropdown from '@components/Dropdown';
import ArrowDown from '@public/svg/arrowV2.svg';

type SelectionProps = {
  close?: Function,
  show?: boolean,
  context?: Object,
};
type Props = {
  listSelections?: Array,
  to?: any,
  from?: any,
  unit?: string,
  min?: number,
  max?: number,
  minDistance?: number,
  onSelectItem?: Function,
  additionalItems?: Array,
  placeholder?: string,
  keyValue?: string,
  step?: number,
  disabled?: boolean,
  theValueIsShow?: string,
  exceptValue?: string,
  deleteValue?: boolean,
  stepRenderLine?: number,
  sliderTitle?: string,
  progress?: number,
  isLong?: boolean,
  showPlus?: boolean,
  renderToValue?: number,
};
export default function FromToSelectorV1(props: Props) {
  const {
    listSelections = [],
    onSelectItem,
    keyValue,
    to,
    from,
    unit,
    min,
    max,
    step,
    additionalItems = [],
    disabled = false,
    theValueIsShow,
    exceptValue,
    deleteValue = () => {},
    stepRenderLine,
    sliderTitle,
    progress,
    isLong,
    showPlus = true,
    renderToValue: toValue,
  } = props;
  const [isToggleDropdown, setIsToggleDropdown] = useState(false),
    [disabledWhenDelete, setDisabledWhenDelete] = useState(false),
    selections = [...(additionalItems || []), ...(listSelections || [])],
    selectedValue = selections.find((e) => e.from === from && e.to === to);

  const renderUnit = useMemo(() => {
    const finalUnit =
      listSelections?.find((e) => e.key === keyValue)?.unit || unit;
    return to > max && showPlus ? '+ ' + ' ' + finalUnit : ' ' + finalUnit;
  }, [showPlus, keyValue, listSelections, max, to, unit]);

  const renderValueText = useMemo(() => {
    const renderToValue = to > toValue ? toValue : to;
    const renderValue = keyValue
      ? listSelections?.find((e) => e.key === keyValue)?.value
      : from + ' - ' + renderToValue + renderUnit;
    if ((!from && !to) || (from === min && to >= max)) {
      return 'Tất cả';
    } else {
      return exceptValue?.length > 0 ? exceptValue : renderValue;
    }
  }, [
    exceptValue,
    from,
    keyValue,
    listSelections,
    max,
    min,
    renderUnit,
    theValueIsShow,
    to,
  ]);
  return (
    <Dropdown
      disabled={disabled || disabledWhenDelete}
      show={isToggleDropdown}
      onToggle={() => setIsToggleDropdown(!isToggleDropdown)}
      DropdownComponent={SliderComponent}
      dropdownContext={{
        show: isToggleDropdown,
        from,
        to,
        key: keyValue,
        unit,
        min,
        max,
        step,
        listSelections: selections,
        onSelectItem: (item) => onSelectItem(item),
        selectedValue,
        theValueIsShow,
        stepRenderLine,
        title: sliderTitle,
        progress,
        isLong,
        showPlus,
      }}
      style={`flex items-center justify-center border border-blue-main h-38px rounded-rd9px bg-white`}>
      <div className="w-full flex flex-row items-center justify-between">
        <div
          className={`flex-1 px-2 text-left text-sm truncate text-blue-main`}>
          {renderValueText}
        </div>
        <div className="flex flex-row items-center space-x-2 mr-2">
          {renderValueText !== 'Tất cả' && (
            <div
              onClick={() => {
                deleteValue && deleteValue();
                setDisabledWhenDelete(true);
                setTimeout(() => {
                  setDisabledWhenDelete(false);
                }, 100);
              }}
              className="w-5 h-5 cursor-pointer">
              <img
                className="w-full h-full ml-1"
                alt="close"
                src="/svg/ico-close.svg"
              />
            </div>
          )}
          <ArrowDown
            fill="#888"
            className={`${
              isToggleDropdown
                ? 'transform rotate-180 duration-300'
                : 'duration-300'
            }`}
          />
        </div>
      </div>
    </Dropdown>
  );
}
export function SliderComponent(props: SelectionProps) {
  const { close } = props,
    {
      listSelections,
      from,
      to,
      unit,
      onSelectItem,
      min = 0,
      max,
      step,
      theValueIsShow,
      stepRenderLine,
      title,
      progress,
      isLong,
      showPlus,
      show,
    } = props.context;
  const [initialFrom, setInitialFrom] = useState(from);
  const [initialTo, setInitialTo] = useState(to);
  const [isShowReactSlider, setShowReactSlider] = useState(false);
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShowReactSlider(true);
      }, 100);
    } else {
      setShowReactSlider(false);
    }
  }, [show]);
  return (
    <div
      className="w-full rounded bg-white overflow-scroll"
      style={{
        minWidth: 300,
        maxHeight: 300,
        boxShadow: '-1px 0 5px 0 rgba(0, 0, 0, 0.12)',
      }}>
      <div
        style={{ fontSize: 11, marginLeft: 16, marginTop: 12 }}
        className="font-bold">
        {title}
      </div>
      <div className="p-4 w-full">
        <div className="mt-6">
          {isShowReactSlider && (
            <ReactSliderComponentV2
              showPlus={showPlus}
              isLong={isLong}
              stepRenderLine={stepRenderLine}
              theValueIsShow={theValueIsShow}
              isAdvanceSearch
              setValue={(values) => {
                setInitialFrom(values[0]);
                setInitialTo(values[1]);
              }}
              max={max}
              min={min}
              step={step}
              unit={unit}
              currentFrom={initialFrom}
              currentTo={initialTo}
              minDistance={step}
              progress={progress}
            />
          )}
        </div>
      </div>
      <div style={{ paddingBottom: 80 }} className="flex-1 px-4 space-y-4 ">
        {listSelections.map((item, index) => {
          const isActiveFromTo =
            initialFrom === item.from && initialTo === item.to;
          return (
            <Radio
              isChecked={isActiveFromTo}
              title={item.value}
              key={index}
              onPress={() => {
                onSelectItem({ from: item.from, to: item.to, key: item.key });
                close();
              }}
            />
          );
        })}
      </div>
      <div
        style={{
          bottom: 0.5,
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        }}
        className="bg-white w-full px-3 py-2 flex flex-row border-t border-gray-300 fixed space-x-2 ">
        <MyButton
          wrapperClass="flex-1"
          onPress={close}
          label="Trở về"
          type="secondary"
        />
        <MyButton
          wrapperClass="flex-1"
          onPress={() => {
            onSelectItem({
              from: initialFrom,
              to: initialTo,
            });
            close();
          }}
          label="Xác nhận"
          type="primary"
        />
      </div>
    </div>
  );
}
