import SelectComponent from '@components/SelectComponent';
import { Controller, useFormContext } from 'react-hook-form';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_LIST_DISTRICT_OF_PROVINCE } from '@schemas/queries/map';
import { convertDataSelections } from '@components/AddressDropdown/CityDropdown';
import DropdownForSearchList from '@components/AdvanceSearchForSearchList/components/DropdownForSearchList';

type Props = {
  hideDeleteButton?: boolean,
};

export default function DistrictButton({ hideDeleteButton }: Props) {
  const { control, watch, setValue } = useFormContext();
  const { province: provinceId } = watch();
  const { data, loading } = useQuery(GET_LIST_DISTRICT_OF_PROVINCE, {
    variables: {
      provinceId,
      limit: 100,
    },
    skip: !provinceId,
  });
  const rawData = [
    { id: '', name: 'Tất cả' },
    ...(data?.getListDistrictsOfProvince?.districts || []),
  ];
  const dataSelections = convertDataSelections(rawData);
  const [disabledWhenDelete, setDisabledWhenDelete] = useState(false);
  return (
    <Controller
      control={control}
      name="district"
      render={({ field: { onChange, value } }) => {
        return (
          <DropdownForSearchList
            titleMobile="Quận/ huyện"
            key={Math.random() + value}
            onDelete={() => {
              onChange('');
              setValue('ward', '');
              setValue('street', '');
              setDisabledWhenDelete(true);
              setTimeout(() => {
                setDisabledWhenDelete(false);
              }, 100);
            }}
            value={value}
            setValue={(nextValue) => {
              onChange(nextValue);
              setValue('ward', '');
              setValue('street', '');
            }}
            disabled={disabledWhenDelete}
            hideDeleteButton={value === '' || hideDeleteButton}
            configs={dataSelections}
            isShowSearchBox
            DropdownComponent={SelectComponent}
            contextProps={{ hasItemRadio: true, loading }}
            customValue={() => {
              return (
                <div
                  className={`flex-1 px-2 text-left text-13 truncate text-blue-main`}>
                  {dataSelections?.find((e) => e.key === value)?.value ||
                    'Chọn giá trị'}
                </div>
              );
            }}
          />
        );
      }}
    />
  );
}
